<template>
  <v-card>
    <v-container fill-height>
      <v-form
        ref="formEmail"
        @submit.prevent="submit"
      >
        <v-row>
          <v-col cols="12">
            <v-alert type="info">
              Marque a caixa para modificar e atualizar o e-mail do usuário
            </v-alert>
          </v-col>
          <v-col
            v-if="updateEmail"
            cols="12"
          >
            <v-alert type="warning">
              Atenção: ao atualizar o e-mail, um novo link de confirmação de
              usuário será enviado.
            </v-alert>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="email"
              :disabled="!updateEmail"
              outlined
              dense
              label="E-mail"
              :rules="$rules.email"
              required
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="updateEmail"
              label="Deseja atualizar e-mail do cadastro?"
            />
          </v-col>
          <v-col class="d-flex justify-space-between">
            <v-btn
              color="primary"
              type="submit"
            >
              {{ submitButtonTitle }}
            </v-btn>
            <v-btn
              color="secondary"
              @click="changeStep(1)"
            >
              Voltar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import ChangeEmailService from '../../../services/change-email.service';
import ChangePasswordService from '../../../services/change-password.service';

export default {
  props: {
    user: {
      type: Object,
      default: () => ({ email: '', cpf: '' }),
    },
  },
  data() {
    return {
      email: '',
      updateEmail: false,
    };
  },
  computed: {
    submitButtonTitle() {
      return this.updateEmail
        ? 'Enviar e alterar o e-mail'
        : ' Enviar no e-mail';
    },
  },
  created() {
    this.email = this.user.email;
  },
  methods: {
    changeStep(step) {
      this.$emit('email-returned', step);
    },

    resetForm() {
      this.email = this.user.email;
    },

    async submit() {
      if (!this.$refs.formEmail.validate()) {
        this.$toast.error('Verifique os campos, por favor!');
        return;
      }

      const params = { ...this.user, email: this.email };

      try {
        if (this.updateEmail) {
          await ChangeEmailService.changeEmail(params);
        }

        await ChangePasswordService.sendEmailWithCode(params);
        this.changeStep(3);
        this.$toast.success('Ação bem sucedida!');
      } catch (error) {
        this.$handleHttpError(error);
      } finally {
        this.resetForm();
      }
    },
  },
};
</script>

<style>
</style>
